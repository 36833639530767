import styled from 'styled-components';

export const MenuItem = styled.span`
  margin: ${props => props.margin || `0 0 0 20px`};
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  color: ${props => props.secondaryColor};
  position: relative;
  cursor: pointer;

  @media screen and (min-width: 767px) {
    font-size: 18px;
  }

  @media screen and (max-width: 766px) {
    font-size: 15px;
  }
`;

export const WaNoDp = styled.div`
  padding: 7px 13px;
  font-size: 15px;
  color: #101010;
  box-shadow: 0px 3px 6px #00000029;
  background: #ffffff 0% 0% no-repeat padding-box;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;

  &::before {
    content: '';
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    border-bottom: 10px solid #ffffff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }
`;

export const HeaderSectionListItem = styled.div`
  display: flex;
  font-size: 15px;
  align-items: center;

  & > p {
    margin-left: 10px;
  }
`;
