export function WhatsAppIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 34.886}
      height={props.size || 34.886}
      viewBox="0 0 34.886 34.886"
      {...props}
    >
      <path d="M0 0h34.886v34.886H0z" fill="none" />
      <path
        d="M17.444 30.611h-.029a13.41 13.41 0 01-7.207-2.121l-4.991 1.3 1.338-4.851-.318-.5a13.134 13.134 0 01-2.018-7c0-11.7 14.3-17.554 22.575-9.283 8.256 8.179 2.46 22.455-9.35 22.455z"
        fill="#1e9848"
        stroke="#fff"
        strokeWidth={2}
      />
      <path
        d="M24.684 20.702l-.012.1c-.4-.2-2.352-1.154-2.715-1.286-.816-.3-.586-.048-2.152 1.746-.233.26-.464.279-.86.1a10.8 10.8 0 01-3.2-1.976 12.074 12.074 0 01-2.209-2.755c-.39-.673.426-.769 1.168-2.175a.732.732 0 00-.033-.7c-.1-.2-.894-2.156-1.227-2.936s-.648-.679-.894-.679a1.981 1.981 0 00-1.821.458c-2.148 2.361-1.606 4.8.232 7.386 3.612 4.727 5.536 5.6 9.055 6.806a5.506 5.506 0 002.5.161 4.1 4.1 0 002.683-1.9 3.294 3.294 0 00.24-1.9c-.1-.18-.359-.279-.759-.459z"
        fill="#fff"
      />
    </svg>
  );
}
