/**
 * This is a reusable component for whatsapp functionality
 * desktop views of multiple headers
 */

import { useSelector } from 'react-redux';
import { MenuItem, WaNoDp } from './index.styles';
import { WhatsAppIcon } from '@/assets/svgExports/WhatsAppIcon';
import { useState } from 'react';
import { whatsappMessageRender } from '@/utils/whatsappMessageRender';
import { useSSRSelector } from '@/redux/ssrStore';

const WhatsAppIconComp = () => {
  const [showWANo, setShowWANo] = useState(false);

  const {
    store_info,
    store_id,
    owner: { phone: registered_phone },
    display_phone,
  } = useSSRSelector((state) => state.storeReducer.store);

  const { userData } = useSelector((state) => ({
    userData: state.userReducer.data,
  }));

  const onContactBtnClick = () => {
    whatsappMessageRender({
      userData,
      phoneNumber: display_phone || registered_phone || '',
      storeUrl: store_info?.store_url,
      storeId: store_id,
    });
  };

  return (
    <MenuItem>
      <WhatsAppIcon onClick={() => setShowWANo(!showWANo)} size="24" />
      {(display_phone || registered_phone) && showWANo && (
        <WaNoDp onClick={onContactBtnClick}>{display_phone || registered_phone}</WaNoDp>
      )}
    </MenuItem>
  );
};

export default WhatsAppIconComp;
